import {
	Button,
	FormControl,
	Grid,
	MenuItem,
	Typography,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
} from '@mui/material';

import { UserContext } from '../../../context/UserContext';

import React, { useEffect, useState, useContext } from 'react';
import { findEstados } from '../../../services/estado.service';
import { cambiarUnEstado } from '../../../services/otg.service';
import { useSnackbar } from '../../../context/SnackBarContext';

export default function CambioEstados({ estadoActual, id_otg }) {
	const [listaEstados, setListaEstados] = useState([]);
	const [nuevoEstado, setNuevoEstado] = useState(estadoActual);
	const [mostrarModalRazon, setMostrarModalRazon] = useState(false);
	const [razonCambio, setRazonCambio] = useState('');

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const datosSession = useContext(UserContext);

	const datosColaborador = {};
	datosColaborador.id_tipo = 5;

	const cambiarEstado = async (estado, razon = 'Flujo normal') => {
		const datosEstadoNew = {
			id_estado: estado,
			razon_cambio: razon,
			id_otg: id_otg,
		};

		if (datosEstadoNew.id_estado === 9) {
			datosEstadoNew.tipo_servicio = 'garantia';
		}

		if (datosEstadoNew.id_estado === 10) {
			datosEstadoNew.tipo_servicio = 'cancelado';
		}

		await cambiarUnEstado(datosEstadoNew)
			.then(() => {
				setMostrarModalRazon(false);
				setRazonCambio('');
				window.location.reload();
			})
			.catch((err) => {
				setMostrarModalRazon(false);
			});
	};

	useEffect(() => {
		try {
			const getEstados = async () => {
				const { data } = await findEstados();
				setListaEstados(data);
			};
			getEstados();
		} catch (error) {
			console.log(error);
		}
	}, []);

	const modalRazon = (
		<Dialog
			onClose={() => {
				setMostrarModalRazon(false);
			}}
			open={mostrarModalRazon}
		>
			<DialogTitle>
				Describa la razón del cambio de estado de la OTG
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Esta razón será registrada en el sistema
				</DialogContentText>
				<TextField
					autoFocus
					margin='dense'
					label='Razón de cambio'
					placeholder='Ej. Error en el proceso de registro'
					fullWidth
					value={razonCambio}
					onChange={(e) => {
						setRazonCambio(e.target.value);
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setMostrarModalRazon(false);
					}}
					variant='outlined'
					sx={{ backgroundColor: 'red', color: 'white' }}
				>
					Cancelar
				</Button>
				<Button
					variant='contained'
					sx={{ backgroundColor: 'red', color: 'white' }}
					onClick={() => {
						cambiarEstado(nuevoEstado, razonCambio);
					}}
				>
					Cambiar estado
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{modalRazon}
			{/* Botton para cambiar el estado de la otg */}
			{(datosSession.id_tipo === 5 || datosSession.id_tipo === 3) && (
				<>
					<Typography style={{ margin: '1rem' }} align='center' variant='h6'>
						Cambiar estados de la OTG
					</Typography>

					<Grid container justifyContent='space-around'>
						<Button
							onClick={() => {
								setMostrarModalRazon(true);
							}}
							variant='contained'
						>
							CAMBIAR ESTADO
						</Button>
						{listaEstados.length !== 0 ? (
							<FormControl>
								<Select
									sx={{ textTransform: 'capitalize' }}
									value={nuevoEstado}
									onChange={(e) => {
										setNuevoEstado(e.target.value);
									}}
								>
									{listaEstados.map((estado, i) => {
										return (
											<MenuItem
												sx={{ textTransform: 'capitalize' }}
												disabled={
													estado.id_estado === 2 ||
													estado.id_estado === 4 ||
													estado.id_estado === 5 ||
													estado.id_estado === 11 ||
													estado.id_estado === 12 ||
													estado.id_estado === 6 ||
													estado.id_estado === 14 ||
													estado.id_estado === 15
												}
												key={i}
												value={estado.id_estado}
											>
												{estado.nombre_estado}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						) : (
							'No hay datos'
						)}
					</Grid>
				</>
			)}
			{(datosSession.id_tipo === 6 ||
				datosSession.id_tipo === 5 ||
				datosSession.id_tipo === 3) && (
				<>
					<Grid container justifyContent='center'>
						<Button
							onClick={() => {
								setNuevoEstado(12);
								setMostrarModalRazon(true);
							}}
							variant='contained'
						>
							ANULAR OTG
						</Button>
					</Grid>
				</>
			)}
			{/* {/* Botton entregar a cliente  */}
			{estadoActual === 7 && (
				<Grid container justifyContent='center'>
					<Button
						color='primary'
						variant='contained'
						style={{ margin: '1rem auto' }}
						onClick={() => {
							if (datosSession.id_tipo === 6) {
								handleClickSnackbar(
									'No tiene permisos para realizar esta acción',
									'error'
								);
							} else {
								cambiarEstado(8);
							}
						}}
					>
						Entregar a cliente
					</Button>
				</Grid>
			)}
		</>
	);
}
