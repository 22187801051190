import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findOneTicket = (id_otg) => {
	return axios
		.post(
			`${API_URL}find_one_ticket`,
			{ id_otg: id_otg },
			{
				headers: {
					Authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};
