import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const crearProveedores = (datosProveedor) => {
	return axios
		.post(`${API_URL}crear_proveedores`, datosProveedor, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};



export const findProveedores = () => {
	return axios
		.get(`${API_URL}find_proveedores`, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findProveedoresTabla = () => {
	return axios
		.get(`${API_URL}find_proveedores_v2`, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			// const proveedores = response.data;
			// const proveedoresTabla = [];

			// proveedores.forEach((_proveedor) => {
			// 	const proveedor = {};
			// 	proveedor.id_proveedor = _proveedor.id_proveedor;
			// 	proveedor.primer_nombre = _proveedor.primer_nombre;
			// 	proveedor.ap_paterno = _proveedor.ap_paterno;
			// 	proveedor.celular = _proveedor.celular;

			// 	proveedoresTabla.push(proveedor);
			// });

			return response;
		});
};
