import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const crearOtg = (datosServicio, datosOtg, datosImagen) => {
	return axios
		.post(`${API_URL}servicios`, datosServicio)
		.then((responseServicio) => {
			datosOtg.id_servicio = responseServicio.data.id_servicio;
			datosImagen.forEach((imagen) => {
				const dato = {
					id_imagen: imagen.id_imagen,
					id_servicio: datosOtg.id_servicio,
				};
				axios.post(`${API_URL}agregar_servicio`, dato);
			});
			return axios.post(`${API_URL}otg`, datosOtg).then((response) => {
				return response;
			});
		})
		.catch((err) => {
			console.log(err.response.data);
		});
};

export const agregarServicio = (datos) => {
	return axios
		.post(`${API_URL}agregar_servicio`, datos, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			// console.log(response);
			return response;
		});
	// .catch((err) => {
	// 	console.log(err.response.data);
	// 	return err;
	// });
};

export const findImagenes = (id_otg) => {
	return axios
		.post(`${API_URL}find_imagenes`, id_otg, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
	// .catch((err) => {
	// 	return err.response.data;
	// });
};

export const upload = async (formData) => {
	// return await axios.post('http://localhost:8080/api/upload', formData, {
	return await axios.post(`${API_URL}upload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const addImages = async (id_servicio, imagenes) => {
	return await axios.post(`${API_URL}add-images`, {
		id_servicio,
		imagenes,
	});
};
