import React, { useState } from 'react';
import { useSnackbar } from '../context/SnackBarContext';

import { useHistory } from 'react-router-dom';
import {
	Grid,
	Typography,
	TextField,
	Card,
	CardContent,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';

import fondo from '../img/fondo.svg';

import { olvidado } from '../services/auth.service';

export default function Olvidado() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const history = useHistory();

	const classes = {};
	const [correo, setCorreo] = useState('');
	const [mostrarModal, setMostrarModal] = useState(false);

	const enviarCorreo = () => {
		olvidado(correo)
			.then((response) => {
				setMostrarModal(true);
			})
			.catch((err) => {
				handleClickSnackbar(err.response.data.message, 'error');
			});
	};

	const modal = (
		<Dialog
			open={mostrarModal}
			onClose={() => {
				setMostrarModal(false);
			}}
		>
			<DialogTitle>Por favor revise su correo</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Enviamos un correo con un link para cambiar su contraseña, también revise la bandeja de no
					deseados.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						history.push('/login');
					}}
					size='small'
					variant='contained'
				>
					aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{modal}
			<Grid
				sx={{
					background: `url(${fondo})`,
					position: 'absolute',
					backgroundRepeat: 'no-repeat ',
					backgroundPosition: 'center bottom',
					backgroundSize: '100vw',
					minHeight: '100vh',
				}}
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				className={classes.background}
			>
				<Card component={Grid} item sm={4}>
					<CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography align='center' gutterBottom variant='h5' component='h2'>
							Recuperar cuenta
						</Typography>
						<TextField
							onChange={(e) => {
								setCorreo(e.target.value);
							}}
							fullWidth
							label='Correo Electronico'
						/>

						<Typography variant='caption' align='center' gutterBottom={true}>
							Recuerda que debes tener acceso al correo para poder recibir el link de recuperacion
							de contraseña
						</Typography>

						<Button sx={{ margin: 'auto' }} variant='contained' size='small' onClick={enviarCorreo}>
							Recuperar contraseña
						</Button>
					</CardContent>
				</Card>
			</Grid>
		</>
	);
}
