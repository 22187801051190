import React, { useState, useEffect } from 'react';
import { useSnackbar } from '../../../context/SnackBarContext';

import { nanoid } from 'nanoid';

import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Paper,
	InputLabel,
	TextField,
	ImageList,
	ImageListItem,
} from '@mui/material';

import { ExpandMore, Edit } from '@mui/icons-material';

import { upload } from '../../../services/imagenes.service';
import { obtenerFallas } from '../../../services/falla.service';
import { addImages } from './../../../services/imagenes.service';
import { updateServicios } from '../../../services/servicios.service';

import { direccion } from '../../../utils/localhost';

export default function Servicio({
	datosColaborador,
	servicios,
	SetDatosOtg,
	dropAcordion,
	index,
}) {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const tiposDeServicios = [
		{
			tipo: ' ',
			label: 'Seleccione uno',
			disabled: true,
		},
		{
			tipo: 'evaluacion',
			label: 'Evaluación',
			disabled: false,
		},
		{
			tipo: 'reparacion',
			label: 'Reparación',
			disabled: false,
		},
		{
			tipo: 'reparacion por garantia',
			label: 'reparación por garantía',
			disabled: false,
		},
		{
			tipo: 'garantia',
			label: 'Garantía',
			disabled: false,
		},
	];

	const estadosServicios = [
		{
			tipo: 'pendiente por calidad',
			label: 'Pendiente por calidad',
			disabled: false,
		},
		{
			tipo: 'en reparación',
			label: 'En reparación',
			disabled: false,
		},
		{
			tipo: 'atendido en calidad',
			label: 'Atendido en calidad',
			disabled: false,
		},
		{
			tipo: 'cancelado',
			label: 'Cancelado',
			disabled: false,
		},
		{
			tipo: 'garantia',
			label: 'Garantia',
			disabled: false,
		},
		{
			tipo: 'anulado',
			label: 'Anulado',
			disabled: false,
		},
	];

	const [fallas, setFallas] = useState([
		{
			id_falla: ' ',
			nombre_falla: 'Selecciona una falla',
			costo: 'Selecciona una falla',
		},
	]);

	const baseServicio = {
		id: nanoid(),
		tipo_servicio: ' ',
		descrip_servicio: '',
		Falla: {id_falla: ' '},
		imagenes: [],
		costo: 0,
		costo_transporte: 0,
	};

	const [dataServicios, setDataServicios] = useState(servicios);

	useEffect(() => {
		setDataServicios(servicios);
	}, [servicios]);

	const [editar, setEditar] = useState(false);

	const updateService = (serviceId, event) => {
		const { name, value } = event;

		const newDataServicios = [...dataServicios];

		const index = newDataServicios.findIndex(
			(servicio) => servicio.id_servicio === serviceId
		);

		if (name === 'id_falla') {
			newDataServicios[index].Falla.id_falla = value;
		} else {
			newDataServicios[index][name] = value;
		}

		setDataServicios(newDataServicios);

		SetDatosOtg(newDataServicios);
	};

	const selectFile = async (index, event) => {
		const selectedFiles = event.files;
		const selectedFilesArray = Array.from(selectedFiles);
		const formData = new FormData();

		for (const image in selectedFilesArray) {
			formData.append('images', selectedFilesArray[image]);
		}

		await upload(formData)
			.then((response) => {
				const storedImages = response.data;

				addImages(index, storedImages)
					.then((response) => {})
					.catch((err) => {
						console.log(err);
					});

				const indexServicio = dataServicios.findIndex(
					(servicio) => servicio.id_servicio === index
				);

				let newDataServicios = [...dataServicios];

				storedImages.forEach((image) => {
					newDataServicios[indexServicio].imagenes.push({ url_imagen: image });
				});

				setDataServicios(newDataServicios);

				SetDatosOtg(newDataServicios);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const guardarEdicion = (servicios) => {
		// dando formato a los datos
		const newDataServicios = servicios.map((servicio) => {
			return {
				id_servicio: servicio.id_servicio,
				tipo_servicio: servicio.tipo_servicio,
				descrip_servicio: servicio.descrip_servicio,
				id_falla: servicio.Falla.id_falla,
				costo: servicio.costo,
				estado: servicio.estado,
				costo_transporte_proveedor: servicio.costo_transporte_proveedor,
			};
		});

		updateServicios(newDataServicios)
			.then(() => {
				handleClickSnackbar('Cambios guardados', 'success');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleEditPermission = () => {
		if (datosColaborador.id_tipo === 3 || datosColaborador.id_tipo === 5) {
			setEditar(!editar);
		} else {
			handleClickSnackbar(
				'No tiene los permisos necesarios para realizar esta acción',
				'error'
			);
		}
	};

	useEffect(() => {
		obtenerFallas()
			.then(({ data }) => {
				setFallas((fallas) => fallas.concat(data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	if (!servicios) return 'Cargando componente...';

	return (
		<>
			<Accordion expanded={dropAcordion}>
				<Grid container justifyContent='space-around' alignItems='center'>
					<Typography variant='subtitle1'>
						Información del servicio {index}
						<IconButton size='small' onClick={handleEditPermission}>
							<Edit />
						</IconButton>
					</Typography>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetails
					sx={{
						paddingX: { sm: '1em', lg: '10rem' },
					}}
				>
					<Grid container alignContent='center'>
						{dataServicios.map((servicio, i) => {
							return (
								<Grid container key={servicio.id_servicio}>
									<Typography variant='h5' gutterBottom>
										Servicio {i + 1}
									</Typography>

									<Grid container justifyContent='space-between'>
										<FormControl sx={{ width: '48%' }}>
											<InputLabel id='tipo-servicio'>Tipo Servicio</InputLabel>
											<Select
												labelId='tipo-servicio'
												label='Tipo servicio'
												name='tipo_servicio'
												value={dataServicios[i].tipo_servicio}
												onChange={(event) => {
													updateService(servicio.id_servicio, event.target);
												}}
												displayEmpty
												disabled={!editar}
											>
												{tiposDeServicios.map((servicio, i) => {
													return (
														<MenuItem
															key={i}
															value={servicio.tipo}
															disabled={servicio.disabled}
														>
															{servicio.label}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										{fallas.length > 1 && (
											<FormControl sx={{ width: '48%' }}>
												<InputLabel id='id-falla'>Detalle de falla</InputLabel>

												<Select
													labelId='id-falla'
													label='Detalle de falla'
													name='id_falla'
													value={dataServicios[i].Falla.id_falla}
													onChange={(event) => {
														updateService(servicio.id_servicio, event.target);
													}}
													displayEmpty
													disabled={!editar}
												>
													{fallas.map((falla) => {
														return (
															<MenuItem
																key={falla.id_falla + servicio.id_servicio}
																value={falla.id_falla}
															>
																{falla.nombre_falla}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										)}
									</Grid>

									<Grid container justifyContent='space-between' sx={{ my: 2 }}>
										<FormControl>
											<InputLabel id='estado-servicio'>
												Estado Servicio
											</InputLabel>
											<Select
												label='Estado servicio'
												labelId='estado-servicio'
												name='estado'
												value={dataServicios[i].estado}
												disabled={!editar}
												displayEmpty
												onChange={(event) => {
													updateService(servicio.id_servicio, event.target);
												}}
											>
												{estadosServicios.map((servicio, i) => {
													return (
														<MenuItem
															key={i}
															value={servicio.tipo}
															disabled={servicio.disabled}
														>
															{servicio.label}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<TextField
											disabled={!editar}
											label='Costo proveedor'
											name='costo'
											type='number'
											value={dataServicios[i].costo}
											onChange={(event) => {
												updateService(servicio.id_servicio, event.target);
											}}
										/>

										<TextField
											disabled={!editar}
											label='Costo transporte proveedor'
											name='costo_transporte_proveedor'
											type='number'
											value={dataServicios[i].costo_transporte_proveedor}
											onChange={(event) => {
												updateService(servicio.id_servicio, event.target);
											}}
										/>
									</Grid>

									<Grid container sx={{ my: 2 }}>
										<FormControl fullWidth>
											<TextField
												disabled={!editar}
												label='Descripción del servicio'
												name='descrip_servicio'
												multiline
												placeholder='Ej. Descostura en la parte inferior del producto.'
												value={dataServicios[i].descrip_servicio}
												onChange={(event) => {
													updateService(servicio.id_servicio, event.target);
												}}
											/>
										</FormControl>
									</Grid>

									<Grid container justifyContent='center'>
										<Button
											id={servicio.id_servicio}
											type='file'
											multiple
											variant='contained'
											component='label'
											onChange={(event) => {
												selectFile(servicio.id_servicio, event.target);
											}}
										>
											Subir fotos
											<input hidden accept='image/*' multiple type='file' />
										</Button>

										<Grid
											container
											direction='row'
											spacing={1}
											justifyContent='center'
										>
											{direccion === 'http://localhost:8080/api/' ? (
												<Typography align='center'>
													Imagenes no disponibles en local
												</Typography>
											) : (
												<ImageList
													sx={{ width: 500, height: 450 }}
													cols={3}
													rowHeight={164}
												>
													{dataServicios[i].imagenes.map((image) => (
														<ImageListItem key={image.id_imagen}>
															<img
																// srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
																// src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
																// src={`https://calidad.gruposfr.com/api/uploads/1703866649057.jpeg`}
																src={`${direccion}uploads/${image.url_imagen}`}
																alt={'imagen'}
																loading='lazy'
																style={{ cursor: 'pointer' }} // Agrega un estilo para indicar que es clickeable
																onClick={() =>
																	window.open(
																		`${direccion}uploads/${image.url_imagen}`,
																		'_blank'
																	)
																}
															/>
														</ImageListItem>
													))}
												</ImageList>
											)}
										</Grid>
									</Grid>
								</Grid>
							);
						})}

						{editar ? (
							<Paper
								component={Grid}
								container
								justifyContent='center'
								// direction='column'
								style={{ padding: '1rem' }}
							>
								<Grid item>
									<Typography>Acciones de servicios:</Typography>
								</Grid>
								<Grid item container justifyContent='center'>
									<Button
										onClick={() => {
											guardarEdicion(servicios);
										}}
										color='secondary'
										variant='contained'
									>
										Guardar Edición
									</Button>

									<Button
										onClick={() => {
											const newDataServicios = [...dataServicios];
											newDataServicios.push(baseServicio);
											setDataServicios(newDataServicios);
										}}
										variant='contained'
									>
										Agregar servicio{' '}
									</Button>
								</Grid>
							</Paper>
						) : null}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
}