import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;

const ingresar = (datos) => {
	const _contrasena = datos.contrasena;
	return axios
		.post(`${API_URL}ingresar`, {
			usuario: datos.usuario,
			contrasena: _contrasena,
		})
		.then((response) => {
			if (response.data.token) {
				localStorage.setItem('colaborador', JSON.stringify(response.data));
			}
			return response;
		});
};

export const verificar = async (dato) => {
	return await axios
		.post(`${API_URL}verificar`, { token: dato })
		.then((response) => {
			return response;
		});
};

export const olvidado = (correo) => {
	return axios
		.post(`${API_URL}olvidado`, { correo: correo })
		.then((response) => {
			return response.data;
		});
};

export const validar_token = (token) => {
	return axios.get(`${API_URL}validar_token/${token}`).then((response) => {
		return response;
	});
};

export const cambiar_contrasena = (datos) => {
	return axios
		.post(`${API_URL}cambiar_contrasena`, datos)
		.then((response) => {
			return response.data.message;
		})
		.catch((err) => {
			return err.message;
		});
};

const logger = { ingresar };
export default logger;
