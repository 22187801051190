import { HashRouter as Router, Route, Switch } from 'react-router-dom';

// COMPONENTS
import Home from './pages/home/Home.component';
import Login from './pages/Login.component';
import Olvidado from './pages/Olvidado.component';
import Recuperar from './pages/Recuperar.component';
import theme from './utils/theme';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from './context/SnackBarContext';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider>
				<Router>
					<Switch>
						<Route exact path='/recuperar/:token' component={Recuperar}></Route>
						<Route exact path='/olvidado' component={Olvidado}></Route>
						<Route exact path='/login' component={Login}></Route>
						<Route exact path='/' component={Home}></Route>
					</Switch>
				</Router>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
