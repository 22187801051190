import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../context/SnackBarContext';

import {
	Grid,
	Button,
	Link,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';

import { VisibilityOff, Visibility } from '@mui/icons-material';

import fondo from '../img/fondo.png';
import logo from '../img/Sinfronteras.png'; // Importa tu logo aquí

import authService from '../services/auth.service';

export default function Login() {
	const history = useHistory();
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [datos, setDatos] = useState({
		usuario: '',
		contrasena: '',
	});

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleIngresar = () => {
		authService.ingresar(datos).then(
			() => {
				window.location.replace('/');
			},
			(err) => {
				handleClickSnackbar(err.response.data.message, 'error');
			}
		);
	};

	return (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			alignContent='center'
			wrap='nowrap'
			sx={{
				background: `url(${fondo})`,
				backgroundRepeat: 'no-repeat ',
				backgroundPosition: 'center bottom',
				backgroundSize: '100vw',
				minHeight: '100vh',
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					handleIngresar();
				}
			}}
		>
			<img
				src={logo}
				alt='Logo'
				style={{
					width: '238.5px',
					height: '152px',
					marginBottom: '4rem',
				}}
			/>

			<TextField
				sx={{
					m: 1,
					width: '25ch',
					'& .MuiOutlinedInput-root': {
						borderRadius: '10px', // Borde redondeado de 10px
					},
				}}
				label='Usuario'
				variant='outlined'
				name='usuario'
				type='text '
				onChange={(e) => setDatos({ ...datos, usuario: e.target.value.toLowerCase() })}
			/>

			<FormControl
				sx={{
					m: 1,
					width: '25ch',
					'& .MuiOutlinedInput-root': {
						borderRadius: '10px', // Borde redondeado de 10px
					},
				}}
				variant='outlined'
			>
				<InputLabel htmlFor='outlined-adornment-password'>Contraseña</InputLabel>
				<OutlinedInput
					id='outlined-adornment-password'
					type={showPassword ? 'text' : 'password'}
					value={datos.contrasena}
					onChange={(e) => setDatos({ ...datos, contrasena: e.target.value })}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								onClick={handleClickShowPassword}
								edge='end'
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label='Contraseña'
				/>
			</FormControl>

			<Button
				sx={{ m: 1 }}
				onClick={() => {
					handleIngresar();
				}}
				variant='contained'
				color='primary'
			>
				Ingresar
			</Button>

			<Link
				onClick={() => {
					history.push('/olvidado');
				}}
				sx={{
					color: '#00a082',
					cursor: 'pointer',
				}}
			>
				Olvidé mi contraseña
			</Link>

			<Typography
				style={{
					bottom: '1rem',
					right: '1rem',
					position: 'absolute',
				}}
				variant='caption'
			>
				2.0.2 270924
			</Typography>
		</Grid>
	);
}
