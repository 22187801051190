import React, { useState, useEffect } from 'react';
import { useSnackbar } from '../context/SnackBarContext';

import { useParams, useHistory } from 'react-router-dom';

import {
	Grid,
	Typography,
	TextField,
	Card,
	CardActions,
	CardContent,
	Button,
	Dialog,
	DialogActions,
} from '@mui/material';

import fondo from '../img/fondo.svg';

import { validar_token, cambiar_contrasena } from '../services/auth.service';

export default function Recuperar() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};
	let { token } = useParams();
	const history = useHistory();

	const [mostrarModal, setMostrarModal] = useState(false);

	const [contrasena, setContrasena] = useState({
		nueva: '',
		confirmacion: '',
	});
	const [tokenValido, setTokenValido] = useState(false);

	const cambiar = () => {
		if (contrasena.nueva === contrasena.confirmacion) {
			const datos = {
				token: token,
				contrasena: contrasena.nueva,
			};

			cambiar_contrasena(datos).then((response) => {
				handleClickSnackbar(response, 'success');
				history.push('/login');
			});
		} else {
			handleClickSnackbar('Ambas contraseñas deben ser iguales', 'error');
		}
	};

	useEffect(() => {
		validar_token(token).then((response) => {
			setTokenValido(response.data.valido);
		});
	}, [token]);

	const modal = (
		<Dialog
			open={mostrarModal}
			onClose={() => {
				setMostrarModal(false);
			}}
		>
			<DialogActions>
				<Button
					onClick={() => {
						history.push('/login');
					}}
					size='small'
					variant='contained'
					color='primary'
				>
					aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);

	const contenido_no_valido = (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			sx={{
				background: `url(${fondo})`,
				position: 'absolute',
				backgroundRepeat: 'no-repeat ',
				backgroundPosition: 'center bottom',
				backgroundSize: '100vw',
				minHeight: '100vh',
			}}
		>
			<Typography align='center' gutterBottom variant='h5' component='h2'>
				Lo sentimos el link de recuperacion ha expirado o no es correcto
			</Typography>
		</Grid>
	);

	const contenido_si_valido = (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			sx={{
				background: `url(${fondo})`,
				position: 'absolute',
				backgroundRepeat: 'no-repeat ',
				backgroundPosition: 'center bottom',
				backgroundSize: '100vw',
				minHeight: '100vh',
			}}
		>
			<Card component={Grid} item xs={12} sm={3}>
				<CardContent>
					<Typography align='center' gutterBottom variant='h5' component='h2'>
						Recuperar cuenta
					</Typography>
					<TextField
						margin='dense'
						type='password'
						onChange={(e) => {
							setContrasena({ ...contrasena, nueva: e.target.value });
						}}
						fullWidth
						label='Nueva contraseña'
					/>
					<TextField
						margin='dense'
						type='password'
						onChange={(e) => {
							setContrasena({ ...contrasena, confirmacion: e.target.value });
						}}
						fullWidth
						label='Confirmar nueva contraseña'
					/>
				</CardContent>

				<CardActions>
					<Button
						style={{ margin: 'auto' }}
						variant='contained'
						size='small'
						color='primary'
						onClick={cambiar}
					>
						Cambiar contraseña
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);

	return (
		<>
			{modal}
			{tokenValido ? contenido_si_valido : contenido_no_valido}
		</>
	);
}
