import { InputLabel, Select, Grid, Button, TextField } from '@mui/material';
import { useSnackbar } from '../../context/SnackBarContext';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import React, { useState } from 'react';

import esLocale from 'date-fns/locale/es';

import {
	reporteFindAllOtgs,
	rendicionCajaChica,
	ejecucionSolicitudesPendientes,
} from '../../services/reportes.service';

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';

import moment from 'moment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { dataTiemposPorEstados } from './controllers/ReporteDeTiemposPorEstado';

import {
	cambiosGarantia,
	internoReparaciones,
} from '../../services/reportes.service';

const capitalizeString = (string) =>
	string[0].toUpperCase() + string.slice(1).toLowerCase();

export default function Reportes() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};
	const [rangoFechas, setRangoFechas] = useState({
		inicio: moment(new Date()).format(),
		fin: moment(new Date()).format(),
	});

	const [reporte, setReporte] = useState('');

	const getReporteFindAllOtgs = (rango) => {
		reporteFindAllOtgs(rango)
			.then((response) => {
				const datos = [];
				response.data.forEach((fila) => {
					if (fila.RecibidoPor === null) {
						fila.RecibidoPor = '';
					}

					const datosReporte = {
						'Número de OTG': fila.id_otg,
						Responsable: fila.responsable,
						'Fecha de Creación': moment(fila.createdAt).format('DD/MM/YYYY'),
						'Estado de OTG': `${fila.Estado.order}. ${capitalizeString(
							fila.Estado.nombre_estado
						)}`,
						'CI Cliente': fila.Cliente.ci,
						'Nombre Cliente': `${fila.Cliente.primer_nombre} ${fila.Cliente.segundo_nombre} ${fila.Cliente.ap_paterno} ${fila.Cliente.ap_materno}`,
						'Nombre Producto': fila.Variacion.nombre_producto,
						'Lugar de registro': fila.Colaborador.usuario,
						'Ultima modificación': moment(fila.updatedAt).format('DD/MM/YYYY'),
						'Registrado por': fila.RecibidoPor.usuario,
						'tipo de transporte': fila.tipo_transporte,
						Barras: fila.Variacion.cod_barras,
						// 'Tipo de servicio': fila.Servicios[0].tipo_servicio,
						'Tipo de servicio': (() => {
							if (fila.Servicios[0]) {
								return fila.Servicios[0].tipo_servicio;
							} else {
								return '';
							}
						})(),
						'Servicio 1': (() => {
							if (fila.Servicios[0]) {
								return fila.Servicios[0].Falla.nombre_falla;
							} else {
								return '';
							}
						})(),
						'Descrip 1': (() => {
							if (fila.Servicios[0]) {
								return fila.Servicios[0].descrip_servicio;
							} else {
								return '';
							}
						})(),
						'Servicio 2': (() => {
							if (fila.Servicios[1]) {
								return fila.Servicios[1].Falla.nombre_falla;
							} else {
								return '';
							}
						})(),
						'Descrip 2': (() => {
							if (fila.Servicios[1]) {
								return fila.Servicios[1].descrip_servicio;
							} else {
								return '';
							}
						})(),
						'Servicio 3': (() => {
							if (fila.Servicios[2]) {
								return fila.Servicios[2].Falla.nombre_falla;
							} else {
								return '';
							}
						})(),
						'Descrip 3': (() => {
							if (fila.Servicios[2]) {
								return fila.Servicios[2].descrip_servicio;
							} else {
								return '';
							}
						})(),
						'Estado 2': (() => {
							if (
								fila.Estado.nombre_estado === 'finalizado' ||
								fila.Estado.nombre_estado === 'ANULADO'
							) {
								return 'finalizado';
							} else {
								return 'En curso';
							}
						})(),
						Regional: fila.Colaborador.Ubicacione.Ciudad.nombre_ciudad,
						'Línea de producto':
							fila.Variacion.Producto_maestro.Linea_producto
								.nombre_linea_producto,
						'Unidad de negocio':
							fila.Variacion.Producto_maestro.Unidad_negocio.cod_unidad_negocio,
						'Nombre Referencia': fila.Variacion.Producto_maestro.referencia,
						'Código Referencia': fila.Variacion.Producto_maestro.cod_referencia,
					};

					datos.push(datosReporte);
				});
				generarExcel(datos, 'Reporte OTGS');
			})
			.catch((err) => {
				handleClickSnackbar(`Error al momento de cargar otgs.`, 'error');
			});
	};

	// * REPORTE 7
	const generarCambiosGarantia = (rango) => {
		cambiosGarantia(rango)
			.then((response) => {
				const datos = [];
				response.data.forEach((fila) => {
					const datosReporte = {
						Fecha: moment(fila.createdAt).format('DD/MM/YYYY'),
						Barras: fila.Otg.Variacion.cod_barras,
						'Cod PLM': fila.Otg.Variacion.cod_plm,
						Proveedor: fila.Otg.Variacion.Producto_maestro.Marca.cod_marca,
						UN: fila.Otg.Variacion.Producto_maestro.Unidad_negocio
							.cod_unidad_negoci,
						Linea:
							fila.Otg.Variacion.Producto_maestro.Linea_producto
								.nombre_linea_prod,
						Temporada: fila.Otg.Variacion.Temporada.nombre_temporada,
						Colex:
							fila.Otg.Variacion.Producto_maestro.Coleccion.nombre_coleccion,
						Color: fila.Otg.Variacion.Color.nombre_color,
						Talla: fila.Otg.Variacion.Talla.nombre_talla,
						Descripción: fila.Otg.Variacion.nombre_producto,
						PVP: fila.Otg.Variacion.Producto_maestro.precio_base,
						'Producto Cambio/Garantía': fila.Variacion.nombre_producto,
						Problemas: (() => {
							let valor = '';
							fila.Otg.Servicios.forEach((servicio) => {
								valor = valor + ' ' + servicio.descrip_servicio;
							});
							return valor;
						})(),
						'Nombre Cliente': `${fila.Otg.Cliente.primer_nombre} ${fila.Otg.Cliente.ap_paterno} `,
						'Origen y destino ': fila.Otg.Colaborador.usuario,
					};
					datos.push(datosReporte);
				});

				generarExcel(datos, 'Cambios por garantía');
			})
			.catch((err) => console.log(err));
	};

	// * REPORTE 8
	const generarRendicionCajaChica = (rango) => {
		rendicionCajaChica(rango)
			.then((response) => {
				const datos = [];

				let costoDiario = 0;
				let costoMensual = 0;
				response.data.forEach((fila, i) => {
					let siguienteFila = {
						fecha_reparado: '',
					};

					if (response.data[i + 1] !== undefined) {
						siguienteFila = response.data[i + 1];
					}

					const datosReporte = {
						Otg: fila.Otg.id_otg,
						'Fecha retorno del proveedor': fila.fecha_reparado,
						Año: moment(fila.fecha_reparado).format('YYYY'),
						Mes: moment(fila.fecha_reparado).format('MMMM'),
						Producto: fila.Otg.Variacion.nombre_producto,
						Detalle: fila.Falla.nombre_falla,
						'Reparacion/Garantía': fila.tipo_servicio,
						'Nombre Factura': fila.Otg.Cliente.razon_social,
						'Costo Proveedor': parseFloat(fila.costo),
						'Costo transporte': parseFloat(fila.costo_transporte_proveedor),
						'Factura/recibo': fila.nro_factura_recibo,
						'Gasto por día': (() => {
							if (siguienteFila.fecha_reparado === fila.fecha_reparado) {
								costoDiario =
									parseFloat(costoDiario) +
									parseFloat(fila.costo) +
									parseFloat(fila.costo_transporte_proveedor);
								return 0;
							} else {
								costoDiario =
									parseFloat(costoDiario) +
									parseFloat(fila.costo) +
									parseFloat(fila.costo_transporte_proveedor);
								const _costoDiario = costoDiario;
								costoDiario = 0;
								return _costoDiario;
							}
						})(),
						'Gasto Semanal': (() => {
							if (
								moment(siguienteFila.fecha_reparado).week() ===
								moment(fila.fecha_reparado).week()
							) {
								costoMensual =
									parseFloat(costoMensual) +
									parseFloat(fila.costo) +
									parseFloat(fila.costo_transporte_proveedor);
								return 0;
							} else {
								costoMensual =
									parseFloat(costoMensual) +
									parseFloat(fila.costo) +
									parseFloat(fila.costo_transporte_proveedor);
								const _costoMensual = costoMensual;
								costoMensual = 0;
								return _costoMensual;
							}
						})(),
					};
					datos.push(datosReporte);
				});

				generarExcel(datos, 'Rendición caja chica');
			})
			.catch((err) => console.log(err));
	};

	// * REPORTE 9
	const generarEjecucionSolicitudesPendientes = (rango) => {
		ejecucionSolicitudesPendientes(rango).then((response) => {
			const datos = [];
			response.data.forEach((fila) => {
				const entregaProveedor = fila.Otg.Historicos.find(
					(element) => element.id_estado === 3
				);

				const datosReporte = {
					Otg: fila.Otg.id_otg,
					'Fecha de reclamo': moment(fila.Otg.createdAt).format('DD/MM/YYYY'),
					Origen: fila.Otg.Colaborador.usuario,
					'CI cliente': fila.Otg.Cliente.ci,
					Cliente:
						fila.Otg.Cliente.primer_nombre + ' ' + fila.Otg.Cliente.ap_paterno,
					Producto: fila.Otg.Variacion.nombre_producto,
					Problema: fila.Falla.nombre_falla,
					'Tipo de servicio': fila.tipo_servicio,
					'Costo de Reparación': fila.costo,
					'Fecha de ingreso a FAL': (() => {
						if (entregaProveedor) {
							return moment(entregaProveedor.updatedAt).format('DD/MM/YYYY');
						} else {
							return '-';
						}
					})(),
					'Fecha estimada de entrega del proveedor': (() => {
						if (entregaProveedor) {
							return moment(entregaProveedor.updatedAt)
								.add(10, 'days')
								.format('DD/MM/YYYY');
						} else {
							return '-';
						}
					})(),
					'Fecha de entrega al cliente': (() => {
						if (entregaProveedor) {
							return moment(fila.Otg.createdAt)
								.add(42, 'days')
								.format('DD/MM/YYYY');
						} else {
							return '-';
						}
					})(),
					'Estado servicio': fila.estado,
					'Estado OTG': fila.Otg.Estado.nombre_estado,
					'Tipo transporte': fila.Otg.tipo_transporte,
					Observaciones: fila.Otg.observaciones,
					'Tiempo transcurrido (Días hábiles)': (() => {
						const fechaInicial = moment(fila.Otg.createdAt);
						const fechaFinal = moment(Date.now());
						const diferencia = fechaFinal.diff(fechaInicial, 'days');
						return diferencia;
					})(),
				};
				datos.push(datosReporte);
			});

			generarExcel(datos, 'Solicitudes pendientes');
		});
	};

	// * REPORTE 10
	const generarTiemposEstados = async (rango) => {
		const data = await dataTiemposPorEstados(rango);

		generarExcel(data, 'Tiempos por estados');
	};

	const generarInternoReparaciones = (rango) => {
		internoReparaciones(rango).then((response) => {
			console.log(response.data);
			const datos = [];
			response.data.forEach((fila) => {
				console.log(fila);
				const datosReporte = {
					Otg: fila.Otg.id_otg,
					'Fecha de entrega': (() => {
						if (fila.Proveedores.length > 0) {
							return moment(fila.Proveedores[0].updatedAt).format('DD/MM/YYYY');
						}
						return '-';
					})(),
					'Fecha retorno': (() => {
						if (!fila.fecha_reparado) {
							return '-';
						}
						return moment(fila.fecha_reparado).format('DD/MM/YYYY');
					})(),
					Mes: (() => {
						if (!fila.fecha_reparado) {
							return '-';
						}
						return moment(fila.fecha_reparado).format('MMMM');
					})(),
					Proveedor: (() => {
						if (fila.Proveedores.length > 0) {
							return fila.Proveedores[0].ap_paterno;
						}
						return '-';
					})(),

					'Id servicio': fila.id_servicio,
					Descripción: fila.Otg.Variacion.nombre_producto,
					'Tipo de falla': fila.Falla.nombre_falla,
					Costo: fila.costo,
					Estado: fila.Otg.Estado.nombre_estado,
					observaciones: fila.Otg.observaciones,
				};
				datos.push(datosReporte);
			});

			generarExcel(datos, 'Solicitudes pendientes');
		});
	};

	const generarExcel = (datosDocumento, nombreDocumento) => {
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		const ws = XLSX.utils.json_to_sheet(datosDocumento);
		const wb = { Sheets: { hoja1: ws }, SheetNames: ['hoja1'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, nombreDocumento + fileExtension);
	};

	const generarReporte = (
		id_reporte,
		rangoFechas = { inicio: '2021-06-01', fin: new Date() }
	) => {
		switch (id_reporte) {
			case '2':
				getReporteFindAllOtgs(rangoFechas);
				break;

			case '7':
				generarCambiosGarantia(rangoFechas);
				break;

			case '8':
				generarRendicionCajaChica(rangoFechas);
				break;

			case '9':
				generarEjecucionSolicitudesPendientes(rangoFechas);
				break;

			case '10':
				generarTiemposEstados(rangoFechas);
				break;

			case '11':
				generarInternoReparaciones(rangoFechas);
				break;

			default:
				handleClickSnackbar(`Seleccione un tipo de reporte`, 'warning');
				break;
		}
	};

	return (
		<>
			<Grid container justify='space-around' direction='column'>
				<Grid xs={6} style={{ margin: '2em  auto' }} item>
					<InputLabel htmlFor='select'>Tipo de reporte</InputLabel>
					<Select
						native
						value={reporte}
						onChange={(e) => {
							setReporte(e.target.value);
						}}
					>
						<option value=''>Selecione un reporte</option>

						<option value='2'>1. Reporte general OTGS</option>

						<option value='7'>2. Cambios por garantía</option>
						<option value='8'>3. Costos Caja Chica</option>
						<option value='9'>4. Otgs Pendientes</option>
						<option value='10'>5. Tiempos por estados</option>
						<option value='11'>6. Control interno de reparaciones</option>
					</Select>
				</Grid>

				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={esLocale}
				>
					<Grid container justifyContent={'center'}>
						<DatePicker
							label='Desde'
							format='dd/mm/yyyy'
							value={rangoFechas.inicio}
							onChange={(e) => {
								setRangoFechas({
									...rangoFechas,
									inicio: moment(e).format(),
								});
							}}
							renderInput={(params) => <TextField {...params} />}
						/>

						<DatePicker
							label='Hasta'
							format='dd/mm/yyyy'
							value={rangoFechas.fin}
							onChange={(e) => {
								setRangoFechas({
									...rangoFechas,
									fin: moment(e).format(),
								});
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
					</Grid>
				</LocalizationProvider>
			</Grid>

			<Grid container justify='center' direction='column'>
				<Button
					onClick={() => {
						generarReporte(reporte);
					}}
					variant='contained'
				>
					Generar Reporte a fecha
				</Button>
				<Button
					onClick={() => {
						generarReporte(reporte, rangoFechas);
					}}
					variant='outlined'
				>
					Generar Reporte en rango
				</Button>
			</Grid>
		</>
	);
}
