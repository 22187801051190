import React, { useState, useEffect } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';
import { Button, Grid, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { obtenerFallas } from '../../services/falla.service';

const columns = [
    { 
        field: 'nombre_falla', 
        headerName: 'NOMBRE', 
        width: 350, // Incrementar el ancho de la columna
        renderCell: (params) => (
            <span style={{ textTransform: 'uppercase' }}>
                {params.value}
            </span>
        ) 
    },
    { 
        field: 'costo', 
        headerName: 'COSTO', 
        width: 200, // Incrementar el ancho de la columna
        renderCell: (params) => (
            <span style={{ textTransform: 'uppercase' }}>
                {params.value}
            </span>
        )
    },
];

export default function VerFallas() {
    const { showSnackbar } = useSnackbar();

    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };

    const [data, setData] = useState([]);
    const [obtenerDatos, setObtenerDatos] = useState(true);

    useEffect(() => {
        if (obtenerDatos) {
            obtenerFallas()
                .then((response) => {
                    const datosFallas = response.data;
                    setData(datosFallas);
                })
                .catch((err) => {
                    handleClickSnackbar(
                        `Error al obtener tabla de costos.`,
                        'error'
                    );
                });
        }
    }, [obtenerDatos]);

    return (
        <Grid direction='column' container>
            <Typography variant='h5' color='initial' align='center'>
                Costos Estimados de Fallas
            </Typography>

            <Box sx={{ height: 650 }}>
                <DataGrid
                    getRowId={(r) => r.id_falla}
                    rows={data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textTransform: 'uppercase',
                        },
                        '& .MuiDataGrid-cell': {
                            textTransform: 'uppercase',
                        },
                    }}
                />
            </Box>
        </Grid>
    );
}

