import React, { useState, useEffect } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';

import { Typography, CircularProgress } from '@mui/material';

import ModalEspere from '../../components/ModalEspere.component';

import { findAllOtgsOrder } from '../../services/otg.service';
import { findProveedores } from '../../services/proveedores.service';

import SeccionReparacion from './SeccionReparacion';

export default function Reparaciones() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [modalEspere, setModalEspere] = useState(false);

	const [datosOtgs, setDatosOtgs] = useState();

	const encontrarOtgs = async () => {
		try {
			const response = await findAllOtgsOrder({ estado: 3 });
			const otgs = response.data;

			otgs.forEach((otg) => {
				otg.expanded = false;

				otg.Servicios.forEach((servicio) => {
					servicio.checked = false;
				});
			});

			setModalEspere(false);
			setDatosOtgs(otgs);
			handleClickSnackbar('Registrado con exito', 'success');
		} catch (error) {
			console.log(error);
			handleClickSnackbar(error.response.data.message, 'error');
		}
	};

	const [proveedores, setProveedores] = useState([]);
	useEffect(() => {
		try {
			async function getProovedores() {
				const { data } = await findProveedores();
				setProveedores(data);
			}

			getProovedores();
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		encontrarOtgs();
	}, []);

	if (!datosOtgs) return <CircularProgress />;

	return (
		<>
			<ModalEspere
				mostrar={modalEspere}
				titulo='Registrando, espere por favor'
			/>

			<Typography align='center' variant='h5' paragraph={true}>
				OTG's en espera de reparación
			</Typography>

			{datosOtgs.map((otg) => {
				return (
					<SeccionReparacion
						key={otg.id_otg}
						otg={otg}
						encontrarOtgs={encontrarOtgs}
						proveedores={proveedores}
						id_externo={otg.id_externo}
					/>
				);
			})}
		</>
	);
}
