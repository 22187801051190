import React from 'react';
import { Grid } from '@mui/material';
import UploadProducts from './UploadProducts';
import Ubicaciones from './Ubicaciones';
import RegistroProveedor from './ProveedoresA';
import RegistroFalla from './CargaFallas';

export default function Ajustes() {
  return (
    <Grid container spacing={3} direction="column">
      <RegistroFalla />
	  <UploadProducts />
      <Ubicaciones />
      <RegistroProveedor />
	  
    </Grid>
  );
}
