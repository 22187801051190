import React, { useState } from 'react';

import { useSnackbar } from '../../../context/SnackBarContext';

import {
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
} from '@mui/material';

import { actualizar, cambiarUnEstado } from '../../../services/otg.service';

export default function ModalFacturado(props) {
	const { open, close, otgSeleccionada, id_colaborador, updateComponent } =
		props;

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const handleClose = () => {
		close(!open);
	};

	const [datos, setDatos] = useState({
		id_otg: otgSeleccionada.id_otg,
		precio: 0,
		nro_factura: 0,
	});

	const handleChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const guardarDatos = async (otgSeleccionada, id_colaborador) => {
		try {
			await actualizar({
				...datos,
				id_otg: otgSeleccionada.id_otg,
				// ubi_origen: otgSeleccionada.ubiDestino.id_ubicacion,
				// ubi_destino: otgSeleccionada.ubiOrigen.id_ubicacion,
			});

			await cambiarUnEstado({
				id_otg: otgSeleccionada.id_otg,
				id_estado: 5,
				id_colaborador: id_colaborador,
			});

			handleClickSnackbar('Precio registrado satisfactoriamente', 'success');

			updateComponent(true);
			close(!open);
		} catch (err) {
			console.log(err);
			handleClickSnackbar('Error al cambiar estado', 'error');
		}
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Ingrese los siguientes datos: </DialogTitle>

				<DialogContent>
					<Grid container justify='center' direction='column'>
						<TextField
							margin='dense'
							onChange={handleChange}
							name='precio'
							label='Precio a pagar por el cliente'
						/>
						<TextField
							margin='dense'
							onChange={handleChange}
							name='nro_factura'
							label='Nro de factura'
						/>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button variant='outlined' sx={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose}>
						Cancelar
					</Button>
					<Button
						variant='contained'
						onClick={() => {
							guardarDatos(otgSeleccionada, id_colaborador);
						}}
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
