import React from 'react';

import {
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';

import { ExpandMore } from '@material-ui/icons';

export default function Producto({ producto }) {
	return (
		<Accordion>
			<Grid container justifyContent='space-around' alignItems='center'>
				<Typography variant='subtitle1'>Información de producto</Typography>
				<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
			</Grid>

			<AccordionDetails
				sx={{
					paddingX: { sm: '1em', lg: '10rem' },
				}}
			>
				<Grid container>
					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Nombre:</Typography>
						<Typography variant='caption'>
							{producto.nombre_producto}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Unidad de negocio:</Typography>
						<Typography variant='caption'>
							{producto.Producto_maestro.Unidad_negocio.cod_unidad_negocio}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Línea de producto:</Typography>
						<Typography variant='caption'>
							{producto.Producto_maestro.Linea_producto.nombre_linea_producto}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Colección:</Typography>
						<Typography variant='caption'>
							{producto.Producto_maestro.Coleccion.nombre_coleccion}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Temporada:</Typography>
						<Typography variant='caption'>
							{producto.Temporada.nombre_temporada}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Color:</Typography>
						<Typography variant='caption'>
							{producto.Color.nombre_color}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>Talla:</Typography>
						<Typography variant='caption'>
							{producto.Talla.nombre_talla}
						</Typography>
					</Grid>

					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='caption'>EAN:</Typography>
						<Typography variant='caption'>{producto.cod_barras}</Typography>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}
