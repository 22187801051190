import React, { useState } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';

import { useHistory } from 'react-router-dom';

import { nanoid } from 'nanoid';

import {
	Typography,
	Grid,
	Button,
	CircularProgress,
	Paper,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
} from '@mui/material';

import { ExpandMore } from '@material-ui/icons';

import { evaluarServicios } from '../../services/otg.service';
import DialogProveedor from './DialogProveedor';
import DialogCancelar from './DialogCancelar';

export default function SeccionReparacion({
	otg,
	encontrarOtgs,
	proveedores,
	id_externo,
}) {
	const { showSnackbar } = useSnackbar();
	const history = useHistory();

	const [serviciosSeleccionados, setServiciosSeleccionados] = useState({});

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const handleCheckServicio = (e, servicioId) => {
		setServiciosSeleccionados((prevState) => ({
			...prevState,
			[servicioId]: e.target.checked,
		}));
	};

	const evaluarOtg = async (id_otg, tipo_servicio, estado) => {
		try {
			await evaluarServicios({ id_otg, tipo_servicio, estado });
			handleClickSnackbar('Evaluado satisfactoriamente', 'success');
			encontrarOtgs();
		} catch (err) {
			console.log(err);
		}
	};

	const [mostrarDialogProveedor, setMostrarDialogProveedor] = useState(false);
	const handleDialogProveedor = () => {
		setMostrarDialogProveedor(false);
	};

	const [mostrarDialogCancelar, setMostrarDialogCancelar] = useState(false);
	const handleDialogCancelar = () => {
		setMostrarDialogCancelar(false);
	};

	const [modalInfo, setModalInfo] = useState({
		texto_modal: '',
		id_estado: '',
		estado: '',
		tipo_servicio: '',
	});

	const modalData = (id_estado, estado, tipo_servicio, textoModal) => {
		setModalInfo({
			id_estado: id_estado,
			estado: estado,
			tipo_servicio: tipo_servicio,
			textoModal: textoModal,
		});

		setMostrarDialogCancelar(true);
	};

	const goToOtg = (id_externo) => {
		history.push(`/otg/${id_externo}`);
	};

	if (!otg) return <CircularProgress />;

	return (
		<>
			<DialogProveedor
				open={mostrarDialogProveedor}
				close={handleDialogProveedor}
				servicios={serviciosSeleccionados}
				id_otg={otg.id_otg}
				proveedores={proveedores}
				encontrarOtgs={encontrarOtgs}
			/>

			<DialogCancelar
				open={mostrarDialogCancelar}
				close={handleDialogCancelar}
				id_otg={otg.id_otg}
				encontrarOtgs={encontrarOtgs}
				modalInfo={modalInfo}
			/>
			<Accordion expanded={true}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Grid item container xs={12} alignContent='center'>
						<Typography
							display='block'
							onClick={() => {
								goToOtg(id_externo);
							}}
						>
							N° OTG <span>{otg.id_otg}</span>
						</Typography>
						<hr />
							<Typography variant='body2' color='initial'>
							Tienda Origen: <span>{otg.Colaborador.Ubicacione.nombre_ubicacion.toUpperCase()}</span>
						</Typography>
						<hr />
						<Typography variant='body2' color='initial'>
							Producto: <span>{otg.Variacion.nombre_producto}</span>
						</Typography>
					</Grid>
				</AccordionSummary>

				<AccordionDetails>
					<Grid container>
						<Grid item container xs={12} md={12} justifyContent='center'>
							<Button
								size='small'
								variant='contained'
								color='primary'
								disabled={otg.evaluado}
								onClick={() => {
									evaluarOtg(otg.id_otg, 'reparacion');
								}}
							>
								Reparación
							</Button>

							<Button
								size='small'
								variant='contained'
								color='primary'
								disabled={otg.evaluado}
								onClick={() => {
									evaluarOtg(otg.id_otg, 'reparacion por garantia');
								}}
							>
								Rep. por garantía
							</Button>

							<Button
								size='small'
								variant='contained'
								color='primary'
								disabled={otg.evaluado}
								onClick={() => {
									modalData(
										9,
										'garantia',
										'garantia',
										'Describa la razón de la garantía'
									);
								}}
							>
								Garantía
							</Button>
							<Button
								size='small'
								variant='contained'
								color='secondary'
								disabled={otg.evaluado}
								onClick={() => {
									modalData(
										10,
										'cancelado',
										'cancelado',
										'Describa la razón de la cancelación'
									);
								}}
							>
								Cancelado
							</Button>
						</Grid>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Registrado como</TableCell>
										<TableCell>Tipo</TableCell>
										<TableCell>Selecionar</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{otg.Servicios.map((servicio) =>
										servicio.estado === 'pendiente por calidad' ? (
											<TableRow key={nanoid()}>
												<TableCell>{servicio.tipo_servicio.toUpperCase()}</TableCell>
												<TableCell>{servicio.Falla.nombre_falla.toUpperCase()}</TableCell>

												<TableCell>
													<Checkbox
														id={servicio.id_servicio.toString()}
														checked={
															serviciosSeleccionados[servicio.id_servicio]
														}
														onChange={(event) =>
															handleCheckServicio(event, servicio.id_servicio)
														}
														inputProps={{
															'aria-label': 'primary checkbox',
														}}
													/>
												</TableCell>
											</TableRow>
										) : null
									)}
								</TableBody>
							</Table>
						</TableContainer>

						<Button
							sx={{ margin: '1rem auto' }}
							onClick={(e) => {
								setMostrarDialogProveedor(true);
							}}
							variant='contained'
							disabled={!otg.evaluado}
						>
							Seleccionar proveedor
						</Button>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
