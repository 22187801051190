import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const obtenerFallas = async () => {
    return await axios.get(`${API_URL}find_all_fallas`, {
        headers: {
            authorization: 'Bearer ' + token,
        },
    });
};

export const crearFalla = (datosFalla) => {
    return axios
    .post(`${API_URL}create_falla`, datosFalla, {
        headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + token,
        }
    })
    .then((response) => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
};

