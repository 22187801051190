import React from 'react';

import { Avatar, Typography, Grid } from '@material-ui/core';

export default function Roadmap() {
	return (
		<>
			<Grid container alignContent='center' direction='column'>
				<Avatar
					alt='CALIDAD'
					src='../logo512.png'
					sx={{ width: '8rem', height: '8rem' }}
				/>
				<Typography variant='h4' color='initial'>
					Calidad
				</Typography>
			</Grid>

			<Typography align='center' variant='h6' color='initial'>
				Roadmap
			</Typography>
			<Grid>
				<Typography variant='subtitle1' color='initial'>
					Busqueda de productos
				</Typography>

				<Typography variant='body2' color='initial'>
					Se analizará la opción de realidar la busqueda del producto
					simplemente por el nombre de la variante de producto, esto ayudará a
					acortar los tiempos de busqueda de producto y por ende los tiempos de
					registro de una otg en tiendas.
				</Typography>
			</Grid>

			<Grid>
				<Typography variant='subtitle1' color='initial'>
					Escanear para cambiar de estado
				</Typography>

				<Typography variant='body2' color='initial'>
					Pensamos que no se está sacando todo el provecho a la función de
					escaner que tiene implementada la aplicación, es por eso que en una
					siguiente versión implementaremos la función de cambiar el estado al
					siguiente correspondiente con simplemente escanearlo o escanearlo y
					seleccionando el estado que se requiere.
				</Typography>
			</Grid>

			<Grid>
				<Typography variant='subtitle1' color='initial'>
					Alertas en la aplicación
				</Typography>

				<Typography variant='body2' color='initial'>
					Notificar a los usuarios sobre lo que está pasando en el flujo de
					reparación es importante, pero hacerlo con correos podría generar una
					gran cantidad de spam a los usuarios, es por eso que tenemos planeado
					implementar notificaciones dentro de la aplicación de calidad para
					mejorar la comunicación con los usuarios.
				</Typography>
			</Grid>

			<Grid>
				<Typography variant='subtitle1' color='initial'>
					Implementar un arbol de fallas
				</Typography>

				<Typography variant='body2' color='initial'>
					Realizar cambios en la base de datos cuando una aplicación que está en
					productivo siempre es complicado, sin embargo planeamos implementar un
					arbol de fallas para filtrar las mismas de acuerdo a la unidad de
					negocio del producto selecionado para reparación.
				</Typography>
			</Grid>
		</>
	);
}
