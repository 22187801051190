import React, { useState } from 'react';

import { useSnackbar } from '../../../context/SnackBarContext';

import {
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Autocomplete,
	Box,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import {
	findMaestro,
	findVariantes,
	findVariacion,
} from '../../../services/productos.service';

import { cambiarUnEstado } from '../../../services/otg.service';

import { registrarCambioProducto } from '../../../services/cambio_producto.service';

export default function ModalCambio(props) {
	const { open, close, id_otg, id_colaborador } = props;

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [productosMaestros, setProductosMaestros] = useState([]);
	const [productosVariantes, setProductosVariantes] = useState([]);
	const [variacionCambio, setVariacionCambio] = useState();

	const columns = [
		{
			field: 'nombre_producto',
			headerName: 'Producto',
			width: '300',
		},
		{
			field: 'talla',
			headerName: 'Talla',
		},
		{
			field: 'color',
			headerName: 'Color',
		},
	];

	const handleClose = () => {
		setVariacionCambio();
		setProductosVariantes([]);
		setProductosMaestros([]);
		close(false);
	};

	const buscarProductoVariante = (event) => {
		if (event.target.value.length >= 2) {
			findMaestro(event.target.value)
				.then(({ data }) => {
					setProductosMaestros(data);
				})
				.catch((err) => {
					handleClickSnackbar(
						'Error al momento de buscar el producto',
						'error'
					);
				});
		}
	};

	// buscar variaciones del producto maestro seleccionado
	const buscarVariaciones = (cod_referencia) => {
		findVariantes(cod_referencia)
			.then((response) => {
				const datos = [];

				for (const elemento of response) {
					const variante = {
						id_variacion: elemento.id_variacion,
						nombre_producto: elemento.nombre_producto,
						talla: elemento.Talla.nombre_talla,
						color: elemento.Color.nombre_color,
					};
					datos.push(variante);
				}

				setProductosVariantes(datos);
			})
			.catch((err) => {
				handleClickSnackbar('El producto no ha sido encontrado.', 'error');
			});
	};

	// Buscar producto variante
	const buscarVariacion = (id_producto_maestro) => {
		findVariacion(id_producto_maestro)
			.then((response) => {
				setVariacionCambio(response);
			})
			.catch((err) => {
				handleClickSnackbar('Error al obtener la variación.', 'error');
			});
	};

	// registrar el cambio del producto
	const cambiar = (id_otg, id_variacion) => {
		registrarCambioProducto({ id_otg, id_variacion })
			.then((response) => {
				cambiarUnEstado({
					id_otg: response.data.datosCambio.id_otg,
					id_estado: 8,
					id_colaborador,
					razon_cambio: 'Cambio por garantía',
				})
					.then((response) => {
						handleClose();

						handleClickSnackbar(
							'Cambio registrado satisfactoriamente',
							'success'
						);
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((err) => {
				handleClickSnackbar('Error al registrar el cambio', 'error');
			});
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle component={Typography} align='center'>
					Seleccione el producto que se dará al cliente
				</DialogTitle>

				<DialogContent>
					{!variacionCambio ? (
						<>
							<Autocomplete
								options={productosMaestros}
								getOptionLabel={(option) =>
									`${option.id_producto_maestro} ${option.referencia} ${option.cod_referencia}`
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Seleccione el maestro de producto'
										variant='outlined'
										onChange={(event) => {
											buscarProductoVariante(event);
										}}
									/>
								)}
								onChange={(e, value) => {
									buscarVariaciones(value.cod_referencia);
								}}
							/>

							{productosVariantes.length !== 0 && (
								<Box sx={{ height: 400, width: '100%' }}>
									<DataGrid
										getRowId={(r) => r.id_variacion}
										columns={columns}
										rows={productosVariantes}
										onRowClick={(value) => {
											console.log(value);
											buscarVariacion(value.id);
										}}
									/>
								</Box>
							)}
						</>
					) : (
						<>
							<Typography align='center' variant='h6'>
								Producto a cambiar:
							</Typography>

							<TableContainer component={Paper}>
								<Table aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell>Nombre de producto</TableCell>
											<TableCell>Color</TableCell>
											<TableCell>Talla</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>{variacionCambio.nombre_variacion}</TableCell>
											<TableCell>{variacionCambio.color}</TableCell>
											<TableCell>{variacionCambio.talla}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
				</DialogContent>

				<DialogActions>
					<Button variant='outlined' onClick={handleClose} color='primary'>
						Cancelar
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							cambiar(id_otg, variacionCambio.id_variacion);
						}}
						disabled={!variacionCambio}
					>
						Cambiar producto
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
