import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React, { useState } from 'react';

import { useSnackbar } from '../../context/SnackBarContext';
import { crearServicioProveedor } from '../../services/servicio_proveedor.service';
import { cambiarUnEstado } from '../../services/otg.service';

export default function DialogProveedor({
	open,
	close,
	servicios,
	id_otg,
	proveedores,
	encontrarOtgs,
}) {
	const [proveedorSeleccionado, setProveedorSeleccionado] = useState('');

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const asignarServicioProveedor = async () => {
		const serviciosTrue = Object.entries(servicios)
			.filter(([id, seleccionado]) => seleccionado)
			.map(([id]) => parseInt(id));

		if (!proveedorSeleccionado) {
			handleClickSnackbar('Seleccione un proveedor', 'error');
			return;
		}

		if (!servicios) {
			handleClickSnackbar('Debe seleccionar al menos un servicio', 'error');
			return;
		}

		try {
			await crearServicioProveedor({
				idServicios: serviciosTrue,
				id_proveedor: proveedorSeleccionado,
			});

			await cambiarUnEstado({
				id_estado: 4,
				id_otg: id_otg,
			});
			handleClickSnackbar('Registrado satisfactoriamente', 'success');
			encontrarOtgs();
		} catch (error) {
			handleClickSnackbar(error.response.data.message, 'error');
		}
	};

	return (
		<Dialog onClose={close} open={open}>
			<DialogTitle>Enviar producto a proveedor:</DialogTitle>
			<DialogContent>
				<FormControl fullWidth sx={{ mt: 1 }}>
					<InputLabel id='lista-proveedores'>Proveedor</InputLabel>
					<Select
						labelId='lista-proveedores'
						label='Proveedor'
						id='proveedor-id-select'
						value={proveedorSeleccionado}
						onChange={(e) => {
							setProveedorSeleccionado(e.target.value);
						}}
					>
						{proveedores.map((proveedor, i) => {
							return (
								<MenuItem key={i} value={proveedor.id_proveedor}>
									{proveedor.ap_paterno}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						close();
					}}
					variant='outlined'
					sx={{ backgroundColor: 'red', color: 'white' }}
				>
					Atras
				</Button>
				<Button
					onClick={() => {
						asignarServicioProveedor();
					}}
					variant='contained'
					color='primary'
				>
					Enviar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
