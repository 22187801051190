import React, { createContext, useEffect, useState } from 'react';
import { verificar } from './../services/auth.service';
import { useHistory } from 'react-router-dom';

const UserContext = createContext();

const UserProvider = ({ children }) => {
	const history = useHistory();
	const [datosSession, setDatosSession] = useState({});

	useEffect(() => {
		const sessionToken = JSON.parse(localStorage.getItem('colaborador'));

		if (!sessionToken) {
			history.push('/');
			return;
		}

		verificar(sessionToken.token)
			.then((response) => {
				setDatosSession(response.data);
			})
			.catch(() => {
				// history.push('/');
				console.log('Sin sesión');
			});
	}, [history]);

	return (
		<UserContext.Provider value={datosSession}>{children}</UserContext.Provider>
	);
};

export { UserContext, UserProvider };
