import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { Link as RouterLink } from 'react-router-dom';

export default function DialogOperation(props) {
	const { open, SetMostrar } = props;

	const handleClose = () => {
		SetMostrar(false);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Seleccione la acción que desea realizar</DialogTitle>

			<DialogActions>
				<Button
					onClick={handleClose}
					variant='contained'
					color='primary'
					component={RouterLink}
					to='/consultar'
				>
					Consultar
				</Button>
				<Button
					onClick={handleClose}
					component={RouterLink}
					to='/recibir'
					variant='contained'
					color='primary'
				>
					Recibir
				</Button>
			</DialogActions>
		</Dialog>
	);
}
