import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const createServicioTransporte = async (
	datosServicioTransporte,
	selectedOtgs
) => {
	return await axios
		.post(
			`${API_URL}create_servicio_transportadora`,
			{ datosServicioTransporte, selectedOtgs },
			{
				headers: {
					authorization: 'Bearer ' + token.token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};

export const getServiciosTransportadoras = (id_otg) => {
	return axios
		.post(`${API_URL}findall_servicios_transportadoras`, id_otg, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findOneServicioTransportadora = (id_servicio_transportadora) => {
	return axios
		.post(
			`${API_URL}find_one_servicio_transportadora`,
			id_servicio_transportadora,
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};

export const updateServicioTransportadora = (datosServicioTransportadora) => {
	return axios
		.put(
			`${API_URL}update_servicio_transportadora`,
			datosServicioTransportadora,
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};
