import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Divider, Button, TextField } from '@mui/material';
import quagga from 'quagga';

import { findExterno } from '../services/otg.service';

const Quagga = quagga;

export default function Consultar() {
	const [idOtg, setIdOtg] = useState('');

	const history = useHistory();

	Quagga.onProcessed((result) => {
		var drawingCtx = Quagga.canvas.ctx.overlay,
			drawingCanvas = Quagga.canvas.dom.overlay;

		if (result) {
			if (result.boxes) {
				drawingCtx.clearRect(
					0,
					0,
					Number(drawingCanvas.getAttribute('width')),
					Number(drawingCanvas.getAttribute('height'))
				);
				result.boxes
					.filter(function (box) {
						return box !== result.box;
					})
					.forEach(function (box) {
						Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
							color: 'green',
							lineWidth: 2,
						});
					});
			}

			if (result.box) {
				Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
					color: '#00F',
					lineWidth: 2,
				});
			}

			if (result.codeResult && result.codeResult.code) {
				Quagga.ImageDebug.drawPath(
					result.line,
					{ x: 'x', y: 'y' },
					drawingCtx,
					{ color: 'red', lineWidth: 3 }
				);
			}
		}
	});

	Quagga.onDetected((data) => {
		Quagga.stop();
		setIdOtg(data.codeResult.code);
		goToOtg(data.codeResult.code);
	});

	const goToOtg = (idOtg) => {
		findExterno(idOtg).then((response) => {
			if (response.status === 200) {
				history.push(`/otg/${response.data.id_externo}`);
			}
		});
	};

	useEffect(() => {
		Quagga.init(
			{
				frequency: 1,
				inputStream: {
					name: 'Live',
					type: 'LiveStream',
					target: document.querySelector('#camera'),
					constraints: {
						facingMode: 'environment',
					},
				},
				decoder: {
					readers: ['code_128_reader'],
				},
			},
			function (err) {
				if (err) {
					console.log(err);
					return;
				}
				Quagga.start();
			}
		);
	}, []);

	return (
		<>
			<Grid container justify='center'>
				<Typography variant='subtitle1' color='initial'>
					CONSULTAR PRODUCTO
				</Typography>

				<Divider />

				<Grid container justifyContent='center' sx={{ m: 3 }}>
					<TextField
						label='OTG'
						autoFocus
						value={idOtg}
						onChange={(e) => {
							setIdOtg(e.target.value);
						}}
					/>

					<Button
						variant='contained'
						onClick={() => {
							goToOtg(idOtg);
						}}
					>
						Ir a la OTG
					</Button>
				</Grid>
			</Grid>
			<div style={{ position: 'relative' }} id='camera' />
		</>
	);
}
