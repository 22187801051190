import { useState } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';
import { Paper, Button, Grid, Typography, TextField } from '@mui/material';
import { crearFalla } from '../../services/falla.service';

export default function RegistroFalla() {
    const { showSnackbar } = useSnackbar();

    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };

    const [datosFalla, setDatosFalla] = useState({
        nombre_falla: '',
        tiempo: '',
        costo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDatosFalla({
            ...datosFalla,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        crearFalla(datosFalla)
            .then((response) => {
                handleClickSnackbar('Falla registrada exitosamente', 'success');
                setDatosFalla({
                    nombre_falla: '',
                    tiempo: '',
                    costo: '',
                });
            })
            .catch((err) => {
                console.error('Error capturado:', err);
                const errorMessage = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : 'Error al crear la falla';
                handleClickSnackbar(`Error al crear la falla. ${errorMessage}`, 'error');
            });
    };

    return (
        <Grid container justifyContent='center' sx={{ padding: 3 }}>
            <Paper elevation={3} sx={{ padding: 6 }}>
                <Typography variant='h6' color='initial' align='center'>
                    Registro de Falla
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name='nombre_falla'
                            margin='dense'
                            fullWidth
                            onChange={handleChange}
                            value={datosFalla.nombre_falla}
                            label='Nombre de la Falla'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='tiempo'
                            margin='dense'
                            fullWidth
                            onChange={handleChange}
                            value={datosFalla.tiempo}
                            label='Ej. 3 - 5 días'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='costo'
                            margin='dense'
                            fullWidth
                            onChange={handleChange}
                            value={datosFalla.costo}
                            label='Ej. 5 - 50 Bs.'
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ margin: '1rem auto' }}
                        onClick={handleSubmit}
                    >
                        Registrar
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    );
}
