import React, { useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    Grid,
    Typography,
    AccordionSummary,
    FormGroup,
    CircularProgress,
    
} from '@mui/material';
import { findCiudades } from '../../../services/ciudades.service';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import _ from 'lodash';

const AccordionCustom = styled(Accordion)`
    padding: 2rem;
    margin: 1rem;
`;

export default function EntregaTiendas({ title, query }) {
    const [ciudades, setCiudades] = useState([]);
    const [otgs, setOtgs] = useState(null);
    const [cambioEstadoExitoso, setCambioEstadoExitoso] = useState(false);
    useEffect(() => {
        try {
            const fetchData = async () => {
                const _otgs = await fetch(query, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${
                            JSON.parse(localStorage.getItem('colaborador')).token
                        }`,
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => data);

                const groupedOtgs = Object.values(
                    _.groupBy(_otgs, 'ubiOrigen.nombre_ubicacion')
                );

                setOtgs(groupedOtgs);
            };
            fetchData();
        } catch (error) {
            console.log(error);
        }
    }, [cambioEstadoExitoso, query]);

    useEffect(() => {
        try {
            async function getModalData() {
                const dataCiudades = await findCiudades();
                setCiudades(dataCiudades.data);
            }

            getModalData();
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            {otgs === null ? (
                <CircularProgress />
            ) : (
                <AccordionCustom>
                    <Grid container justifyContent='center'>
                        <Grid container direction='row' justifyContent='space-between'>
                            <Typography variant='h6'>{title}</Typography>
                            <Typography variant='h6'>{_.flatMapDeep(otgs).length}</Typography>
                        </Grid>
                        <AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
                    </Grid>
                    <AccordionDetails>
                        <Grid container direction='column'>
                            {otgs.map((ubicacion, i) => {
                                return (
                                    <Grid key={i} container direction='column' sx={{ m: 1 }}>
                                        <FormGroup>
                                            {ubicacion.map((otg) => {
                                                return (
                                                    <Grid
                                                        key={otg.id_otg}
                                                        container
                                                        justifyContent='space-around'
                                                        sx={{ m: 1, border: '1px solid #f2f2f2' }}
                                                    >
                                                        <Typography sx={{ margin: 'auto 0' }}>
                                                            {`OTG:  ${otg.id_otg}`}
                                                        </Typography>
                                                        <Typography sx={{ margin: 'auto 0' }}>
                                                            {`Destino:  ${otg.ubiDestino.nombre_ubicacion}`}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </FormGroup>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </AccordionDetails>
                </AccordionCustom>
            )}
        </>
    );
}

