import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	FormControl,
	Select,
	MenuItem,
	Button,
	InputLabel,
	Divider,
	FormHelperText,
	TextField,
} from '@mui/material';

import { ExpandMore, Delete } from '@mui/icons-material';

import { obtenerFallas } from '../../../services/falla.service';

import { upload } from '../../../services/imagenes.service';

import { styled } from '@mui/material/styles';

const AccordionDetailsCustom = styled(AccordionDetails)`
	display: flex;
	justify-content: center;
`;

export default function Servicio(props) {
	const tiposDeServicios = [
		{
			tipo: ' ',
			label: '',
			disabled: true,
		},
		{
			tipo: 'evaluacion',
			label: 'Evaluación',
			disabled: false,
		},
		{
			tipo: 'reparacion',
			label: 'Reparación',
			disabled: false,
		},
		{
			tipo: 'reparacion por garantia',
			label: 'reparación por garantía',
			disabled: false,
		},
	];

	const baseServicio = {
		id: nanoid(),
		tipo_servicio: '',
		descrip_servicio: '',
		id_falla: '',
		costo: 'Seleccione una falla',
		imagenes: [],
		estado: 'pendiente por calidad',
	};

	const [dataServicios, setDataServicios] = useState([baseServicio]);

	const borrarServicio = (servicioId) => {
		const newDataServicio = [...dataServicios];
		const index = newDataServicio.findIndex(
			(servicio) => servicio.id === servicioId
		);

		newDataServicio.splice(index, 1);
		console.log(newDataServicio);
		setDataServicios(newDataServicio);
		props.getServiciosFromComponent(newDataServicio);
	};

	const updateService = (serviceId, event) => {
		const { name, value } = event;

		const newDataServicios = [...dataServicios];

		const index = newDataServicios.findIndex(
			(servicio) => servicio.id === serviceId
		);

		newDataServicios[index][name] = value;

		if (name === 'id_falla') {
			const indexFalla = fallas.findIndex((falla) => falla.id_falla === value);
			const costo = fallas[indexFalla].costo;
			newDataServicios[index].costo = costo;
		}

		setDataServicios(newDataServicios);

		props.getServiciosFromComponent(newDataServicios);
	};

	const [fallas, setFallas] = useState([
		{
			id_falla: ' ',
			nombre_falla: 'Selecciona una falla',
			costo: 'Selecciona una falla',
		},
	]);

	const selectFile = async (index, event) => {
		const selectedFiles = event.files;
		const selectedFilesArray = Array.from(selectedFiles);
		const formData = new FormData();

		for (const image in selectedFilesArray) {
			formData.append('images', selectedFilesArray[image]);
		}

		await upload(formData)
			.then((response) => {
				const storedImages = response.data;

				const indexServicio = dataServicios.findIndex(
					(servicio) => servicio.id === index
				);

				let newDataServicios = [...dataServicios];

				newDataServicios[indexServicio].imagenes =
					newDataServicios[indexServicio].imagenes.concat(storedImages);

				setDataServicios(newDataServicios);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		obtenerFallas()
			.then((response) => {
				setFallas((fallas) => fallas.concat(response.data));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<Accordion expanded={props.dropAcordion}>
			<Grid container justifyContent='space-around' alignItems='center'>
				<Typography variant='subtitle1'>
					Información del servicio {props.index}
				</Typography>

				<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
			</Grid>

			<AccordionDetailsCustom>
				<Grid item md={10}>
					{dataServicios.map((servicio, i) => {
						return (
							<Grid container key={servicio.id}>
								<Typography variant='h6' sx={{ mb: 1 }}>
									Servicio {i + 1}
								</Typography>
								<Delete
									sx={{ color: 'red', mt: 0.5 }}
									onClick={() => {
										borrarServicio(servicio.id);
									}}
								/>

								<Grid container justifyContent='space-between'>
									<FormControl sx={{ width: '48%' }}>
										<InputLabel id='tipo-de-servicio-label'>
											Tipo de servicio
										</InputLabel>
										<Select
											name='tipo_servicio'
											labelId='tipo-de-servicio-label'
											label='Tipo de servicio'
											value={dataServicios[i].tipo_servicio}
											onChange={(event) => {
												updateService(servicio.id, event.target);
											}}
										>
											{tiposDeServicios.map((servicio, i) => {
												return (
													<MenuItem
														key={i}
														value={servicio.tipo}
														disabled={servicio.disabled}
													>
														{servicio.label}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>

									<FormControl sx={{ width: '48%' }}>
										<InputLabel id='falla '>Detalle de falla</InputLabel>
										<Select
											labelId='falla'
											label='Detalle de falla'
											name='id_falla'
											value={dataServicios[i].id_falla}
											onChange={(event) => {
												event.target.costo = dataServicios[i].costo;
												updateService(servicio.id, event.target);
											}}
											displayEmpty
										>
											{fallas.map((falla, i) => {
												return (
													<MenuItem key={i} value={falla.id_falla}>
														{falla.nombre_falla}
													</MenuItem>
												);
											})}
										</Select>
										<FormHelperText>
											Aprox:{dataServicios[i].costo}
										</FormHelperText>
									</FormControl>
								</Grid>
								<TextField
									fullWidth
									label='Descripción del servicio'
									name='descrip_servicio'
									multiline
									placeholder='Ej. Descostura en la parte inferior del producto.'
									onChange={(event) => {
										updateService(servicio.id, event.target);
									}}
								/>
								<Grid container justifyContent='center'>
									<Button
										variant='contained'
										component='label'
										onChange={(event) => {
											selectFile(servicio.id, event.target);
										}}
									>
										Subir fotos
										<input hidden accept='image/*' multiple type='file' />
									</Button>

									<Divider variant='middle' />

									<Grid container direction='row' spacing={1}>
										{dataServicios[i].imagenes.map((image) => {
											return (
												<Grid key={nanoid()} item xs={4}>
													<img
														style={{ maxWidth: '200px', maxHeight: '300px' }}
														src={`http://localhost:8080/api/uploads/${image}`}
														alt={image}
													/>
												</Grid>
											);
										})}
									</Grid>
								</Grid>
							</Grid>
						);
					})}

					<Grid container justifyContent='center'>
						<Button
							onClick={() => {
								setDataServicios([...dataServicios, baseServicio]);
							}}
							variant='contained'
						>
							Agregar otro servicio{' '}
						</Button>
					</Grid>
				</Grid>
			</AccordionDetailsCustom>
		</Accordion>
	);
}
