import React, { useState, useEffect } from 'react';
import { useSnackbar } from '../context/SnackBarContext';

import { Button, Grid, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { findProveedoresTabla } from '../services/proveedores.service';

const columns = [
    { field: 'id_proveedor', headerName: 'ID', width: 100 },
    { field: 'primer_nombre', headerName: 'Nombre', width: 200 },
    { field: 'ap_paterno', headerName: 'Ap. Paterno', width: 100 },
    { field: 'celular', headerName: 'Celular', width: 100 },
    { field: 'direccion', headerName: 'Dirección', width: 200 },
    { field: 'cantidad', headerName: 'Cantidad', width: 100 },
];

export default function VerProveedores() {
    const { showSnackbar } = useSnackbar();

    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };

    const [data, setData] = useState([]);
    const [obtenerDatos, setObtenerDatos] = useState(true);

    useEffect(() => {
        if (obtenerDatos) {
            findProveedoresTabla()
                .then((response) => {
                    const datosProveedor = response.data;

                    for (const proveedor of datosProveedor) {
                        let resultado = proveedor.Servicios.filter((servicio) => {
                            return servicio.estado === 'en reparación';
                        });

                        proveedor.Servicios = resultado;
                        proveedor.cantidad = proveedor.Servicios.length;
                    }

                    setData(datosProveedor);
                })
                .catch((err) => {
                    handleClickSnackbar(
                        `Error al obtener tabla de proveedores.`,
                        'error'
                    );
                });
        }
    }, [obtenerDatos]);

    return (
        <Grid direction='column' container>
            <Typography variant='h5' color='initial' align='center'>
                Proveedores registrados
            </Typography>

            <Box sx={{ height: 600 }}>
                <DataGrid
                    getRowId={(r) => r.id_proveedor}
                    rows={data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </Box>

            
        </Grid>
    );
}
