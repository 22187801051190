import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';

import { findAllForDestino, cambiarEstado } from '../../services/otg.service';

import _ from 'lodash';

import { ExpandMore } from '@material-ui/icons';

import {
	Accordion,
	AccordionDetails,
	Grid,
	Typography,
	AccordionSummary,
	Checkbox,
	FormGroup,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ModalEspere from '../../components/ModalEspere.component';
import { UserContext } from '../../context/UserContext';

const AccordionCustom = styled(Accordion)`
	padding: 2rem;
	margin: 1rem;
`;

export default function Calidad() {
	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const [mostrar, setMostrar] = useState(false);

	const [otgsTransporte, setOtgsTransporte] = useState([]);
	const [otgsTienda, setOtgsTienda] = useState([]);

	const [arrayOtgs, setArrayOtgs] = useState([]);

	// const [datosSession, setDatosSession] = useState({});

	const [cantidad, setCantidad] = useState({
		transporte: '',
	});

	const [modalEspere, setModalEspere] = useState(false);
	const [circularProgress, setCircularProgress] = useState(true);

	const guardarArray = (
		e,
		id_estado,
		id_colaborador,
		tipo_transporte,
		razon_cambio = 'Flujo normal'
	) => {
		if (e.target.checked) {
			setArrayOtgs([
				...arrayOtgs,
				{
					id_otg: e.target.value,
					id_estado,
					id_colaborador,
					tipo_transporte,
					razon_cambio,
				},
			]);
		} else {
			for (let i = 0; i < arrayOtgs.length; i++) {
				if (arrayOtgs[i].id_otg === e.target.value) {
					arrayOtgs.splice(i, 1);
				}
			}
		}
	};

	const registrarEstado = async () => {
		setModalEspere(true);

		await cambiarEstado(arrayOtgs).then(async () => {
			setMostrar(false);
			setArrayOtgs([]);
			await getAllForDestino(datosSession);
		});
		setModalEspere(false);
	};

	const getAllForDestino = async (session) => {
		try {
			const otgsTransporte = await findAllForDestino({
				id_estado: 14,
				id_ubicacion: session.id_ubicacion,
			});

			const otgs = otgsTransporte.data;
			const otgsConTransportadora = otgs.filter(
				(otg) =>
					otg.Servicios_transportadoras.length !== 0 ||
					otg.ubiOrigen.Ciudad.id_ciudad === 1
			);

			setCantidad((cantidad) => ({
				...cantidad,
				transporte: otgsConTransportadora.length,
			}));
			const _data = otgsConTransportadora;
			const data = _.groupBy(_data, 'ubiOrigen.nombre_ubicacion');
			setOtgsTransporte(Object.values(data));
			setCircularProgress(false);

			const otgTienda = await findAllForDestino({
				id_estado: 1,
				id_ubicacion: session.id_ubicacion,
			});
			const enTienda = _.filter(otgTienda.data, ['req_transporte', false]);

			setCantidad((cantidad) => ({
				...cantidad,
				tienda: enTienda.length,
			}));

			const entiendaAgrupado = _.groupBy(
				enTienda,
				'ubiOrigen.nombre_ubicacion'
			);
			setOtgsTienda(Object.values(entiendaAgrupado));
			setCircularProgress(false);
		} catch (error) {
			console.log(error);
		}
	};
	// const getAllForDestino = async (session) => {
	// 	await findAllForDestino({
	// 		id_estado: 2,
	// 		id_ubicacion: session.id_ubicacion,
	// 	})
	// 		.then((response) => {
	// 			const otgs = response.data;
	// 			const otgsConTransportadora = otgs.filter(
	// 				(otg) =>
	// 					otg.Servicios_transportadoras.length !== 0 ||
	// 					otg.ubiOrigen.Ciudad.id_ciudad === 1
	// 			);

	// 			setCantidad((cantidad) => ({
	// 				...cantidad,
	// 				transporte: otgsConTransportadora.length,
	// 			}));
	// 			const _data = otgsConTransportadora;
	// 			const data = _.groupBy(_data, 'ubiOrigen.nombre_ubicacion');
	// 			setOtgsTransporte(Object.values(data));
	// 			setCircularProgress(false);
	// 		})
	// 		.catch((err) => {
	// 			handleClickSnackbar(err.response.data.message, 'error');
	// 		});

	// 	await findAllForDestino({
	// 		id_estado: 1,
	// 		id_ubicacion: session.id_ubicacion,
	// 	}).then((response) => {
	// 		const enTienda = _.filter(response.data, ['req_transporte', false]);

	// 		setCantidad((cantidad) => ({
	// 			...cantidad,
	// 			tienda: enTienda.length,
	// 		}));

	// 		const _data = enTienda;
	// 		const data = _.groupBy(_data, 'ubiOrigen.nombre_ubicacion');
	// 		setOtgsTienda(Object.values(data));
	// 		setCircularProgress(false);
	// 	});
	// };

	const datosSession = useContext(UserContext);

	useEffect(() => {
		getAllForDestino(datosSession);
	}, [datosSession]);

	const modal = (
		<Dialog
			onClose={() => {
				setMostrar(false);
			}}
			open={mostrar}
		>
			<DialogTitle>Confirme la recepcion de las OTGs:</DialogTitle>
			<DialogContent>
				{arrayOtgs.map((otg, i) => {
					return (
						<Typography key={i} variant='body2' align={'center'}>
							Número de OTG: <span>{otg.id_otg}</span>
						</Typography>
					);
				})}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setMostrar(false);
					}}
					variant='outlined'
					sx={{ backgroundColor: 'red', color: 'white' }}
				>
					Cancelar
				</Button>
				<Button
					onClick={() => {
						registrarEstado();
					}}
					variant='contained'
				>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{modal}
			<ModalEspere
				titulo='Registrando, por favor espere'
				mostrar={modalEspere}
			/>
			<AccordionCustom>
				<Grid container justifyContent='center'>
					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='h6'>DE TRANSPORTE</Typography>
						{circularProgress ? (
							<CircularProgress />
						) : (
							<Typography variant='h6'>{cantidad.transporte}</Typography>
						)}
					</Grid>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetails>
					<Grid container direction='column'>
						{otgsTransporte.map((ubicacion, i) => {
							return (
								<Grid key={i} container direction='column'>
									<Typography align='center' variant='h6'>
										{ubicacion[0].ubiOrigen.nombre_ubicacion}
									</Typography>
									<FormGroup>
										{ubicacion.map((otg) => {
											return (
												<Grid
													key={otg.id_otg}
													container
													justifyContent='space-around'
												>
													<Typography
														sx={{ margin: 'auto 0' }}
													>{`OTG:  ${otg.id_otg}`}</Typography>

													<Typography
														sx={{ margin: 'auto 0' }}
													>{`Destino:  ${otg.ubiDestino.nombre_ubicacion}`}</Typography>

													<Checkbox
														color='primary'
														value={otg.id_otg}
														onChange={(e) => {
															const estadoCalidad = 3;
															const tipoTransporte = 'ida';
															guardarArray(
																e,
																estadoCalidad,
																datosSession.id_colaborador,
																tipoTransporte
															);
														}}
													/>
												</Grid>
											);
										})}
									</FormGroup>
								</Grid>
							);
						})}
					</Grid>
				</AccordionDetails>
			</AccordionCustom>

			<AccordionCustom>
				<Grid container justifyContent='center'>
					<Grid container direction='row' justifyContent='space-between'>
						<Typography variant='h6'>DE TIENDA</Typography>
						{circularProgress ? (
							<CircularProgress />
						) : (
							<Typography variant='h6'>{cantidad.tienda}</Typography>
						)}
					</Grid>

					<AccordionSummary expandIcon={<ExpandMore />}></AccordionSummary>
				</Grid>

				<AccordionDetails>
					<Grid container direction='column'>
						{otgsTienda.map((ubicacion, i) => {
							return (
								<Grid
									key={i}
									container
									direction='column'
									style={{ margin: '1rem auto' }}
								>
									<Typography align='center' variant='h6'>
										{ubicacion[0].ubiOrigen.nombre_ubicacion}
									</Typography>
									<FormGroup>
										{ubicacion.map((otg) => {
											return (
												<Grid
													key={otg.id_otg}
													container
													justifyContent='space-around'
												>
													<Typography
														style={{ margin: 'auto 0' }}
													>{`OTG:  ${otg.id_otg}`}</Typography>

													<Typography
														style={{ margin: 'auto 0' }}
													>{`Destino:  ${otg.ubiDestino.nombre_ubicacion}`}</Typography>

													<Checkbox
														color='primary'
														value={otg.id_otg}
														onChange={(e) => {
															const estadoCalidad = 3;
															const tipoTransporte = 'ida';
															guardarArray(
																e,
																estadoCalidad,
																datosSession.id_colaborador,
																tipoTransporte
															);
														}}
													/>
												</Grid>
											);
										})}
									</FormGroup>
								</Grid>
							);
						})}
					</Grid>
				</AccordionDetails>
			</AccordionCustom>

			<Grid container justifyContent='center'>
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						if (datosSession.id_tipo === 6) {
							handleClickSnackbar(
								'No está autilizado para realizar esta acción',
								'error'
							);
						} else {
							setMostrar(true);
						}
					}}
				>
					Registrar
				</Button>
			</Grid>
		</>
	);
}
