import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

class ColaboradorService {
	login() {
		return localStorage.setItem('colaborador', 'franco');
	}

	logout() {
		localStorage.removeItem('colaborador');
	}

	checkLogin() {
		return localStorage.getItem('colaborador');
	}
}

const colaboradorService = new ColaboradorService();
export default colaboradorService;

export const findByType = (id_tipo) => {
	return axios
		.post(
			`${API_URL}colaboradores/find_by_type`,
			{ id_tipo },
			{
				headers: {
					authorization: 'Bearer ' + token,
				},
			}
		)
		.then((response) => {
			return response;
		});
};

export const findAll = async () => {
	return await axios.get(`${API_URL}colaboradores_find_all`, {
		headers: {
			authorization: 'Bearer ' + token,
		},
	});
};
