import { direccion } from '../utils/localhost';
import axios from 'axios';
const API_URL = direccion;
const token = JSON.parse(localStorage.getItem('colaborador'));

export const findTipoUbicacion = (datos) => {
	return axios
		.post(`${API_URL}find_tipo_ubicacion`, datos, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findUbicacion = (datos) => {
	return axios
		.post(`${API_URL}find_ubicacion`, datos, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findAllUbicaciones = () => {
	return axios
		.get(`${API_URL}find_all_ubicaciones`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};

export const findTransportadorasCiudad = async (datos) => {
	return await axios
		.post(`${API_URL}find_transportadoras_ciudad`, datos, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response.data;
		});
};

export const crearUbicacion = (datosUbicacion) => {
	return axios
		.post(`${API_URL}crear_ubicacion`, datosUbicacion, {
			headers: {
				authorization: 'Bearer ' + token,
			},
		})
		.then((response) => {
			return response;
		});
};
