import React, { useContext, useState, useEffect } from 'react';

import { direccion as API_URL } from '../../utils/localhost';
import Section from './components/Section';
import EntregaTiendas from './components/EntregaTienda';
import { UserContext } from '../../context/UserContext';
import { Typography } from '@mui/material';

export default function Transporte() {
    const datosSession = useContext(UserContext);
    const [otgsRecibidasDeCalidad, setOtgsRecibidasDeCalidad] = useState([]);

    let queryTransportadora = '';

    // query para transportadora
    // Para LP
    if (datosSession.id_ciudad === 1) {
        queryTransportadora = `${API_URL}v1/otgs/find_filtered_otgs?id_estado=2&evaluado=true&origen_ciudad=${datosSession.id_ciudad}&destino_ciudad=2,3`;
    }

    // Para SC o CO
    if (datosSession.id_ciudad === 2 || datosSession.id_ciudad === 3) {
        queryTransportadora = `${API_URL}v1/otgs/find_filtered_otgs?id_estado=2&evaluado=false&origen_ciudad=${datosSession.id_ciudad}`;
    }

    useEffect(() => {}, [datosSession.id_ciudad]);

    const handleConfirmCalidad = (confirmedOtgs) => {
        setOtgsRecibidasDeCalidad([...otgsRecibidasDeCalidad, ...confirmedOtgs]);
    };

    const mostrarEntregarCalidad =
        datosSession.id_ciudad === 1 && otgsRecibidasDeCalidad.length === 0;

    if (Object.keys(datosSession).length !== 0) {
        return (
            <>
                <Typography variant='h5'>Recibir</Typography>
                <Section
                    title={'En tiendas'}
                    query={`${API_URL}v1/otgs/find_filtered_otgs?id_estado=1&req_transporte=true&origen_ciudad=${datosSession.id_ciudad}`}
                    nextState={2}
                    confirmTitle='Confirme que está recogiendo las siguientes OTGS:'
                    buttonText='Recibir de tienda'
                />
                <Section
                    title={'En calidad'}
                    query={`${API_URL}v1/otgs/find_filtered_otgs?id_estado=5,10&req_transporte=true&origen_ciudad=${datosSession.id_ciudad}`}
                    nextState={2}
                    confirmTitle='Confirme que está recogiendo las siguientes OTGS:'
                    buttonText='Recibir de calidad'
                    onConfirm={handleConfirmCalidad}
                />
                <Section
                    title={'En transportadora'}
                    query={`${API_URL}v1/otgs/find_filtered_otgs?id_estado=11&destino_ciudad=${datosSession.id_ciudad}`}
                    nextState={2}
                    confirmTitle='Confirme que está recogiendo las siguientes OTGS:'
                    buttonText='Recibir de transportadora'
                />
                
                <Typography variant='h5'>Entregar</Typography>
                <EntregaTiendas
                    title={'A Tienda'}
                    query={`${API_URL}v1/otgs/find_filtered_otgs?id_estado=2&destino_ciudad=${datosSession.id_ciudad}&origen_ciudad=${datosSession.id_ciudad}&evaluado=true`}
                    
                />
                <Section
                    title={'A Transportadora'}
                    query={queryTransportadora}
                    nextState={11}
                    confirmTitle='Confirme que está entregando las siguientes OTGS:'
                    buttonText='Transportadora'
                />
                
                {mostrarEntregarCalidad ? (
                    <Section
                        title={'A Calidad'}
                        query={`${API_URL}v1/otgs/find_filtered_otgs?id_estado=2&destino_ciudad=${1}&origen_ciudad=${1}&filtrar_origen=19`}
                        nextState={3}
                        confirmTitle='Confirme que está entregando las siguientes OTGS:'
                        buttonText='Entregar a GyR'
                    />
                ) : null}
                
                
                
            </>
        );
    } else {
        return <> </>;
    }
}

